import React, { FC } from 'react';

import List from '../List';
import Testimonials from '../Testimonials';
import styled from 'styled-components';
import { smMobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage } from 'components';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import BannerOne from '../BannerOne';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import ImageTextCheckBoxSection from '../ImageTextCheckBoxSection';
import NumberedList from '../NumberedList';
import ImageGrid from '../ImageGrid';
import ReviewsCardsList from '../ReviewCardsList';
import UpgradeDisclaimer from '../UpgradeDisclaimer';

interface PaymentStepsProps {
  firstStepTitle: string;
  secondStepTitle: string;
  thirdStepTitle: string;
  optionalStepTitle?: string;
}

interface BannerOneProps {
  eBookTitle: string;
  eBookLabel: string;
  paperbackTitle: string;
  paperbackLabel: string;
  iconUrl: string;
}

interface CheckBoxItem {
  title: string;
  text: string;
}
interface ImageTextCheckBoxSectionProps {
  image: string;
  title: string;
  mainColor: string;
  checkBoxes: {
    bgColor: string;
    items: CheckBoxItem[];
  };
}

interface PlanSectionProps {
  bgHex: string;
  imgUrl: string;
  oneTimeText: string;
  originalPrice: string;
  only: string;
  additional: string;
  yesButtonTitle: string;
  noButtonTitle: string;
  mainColor: string;
}

interface ImageCard {
  title: string;
  url: string;
}

interface ImageGridProps {
  bgColor: string;
  cardStyle: string;
  title: string;
  subtext: string;
  imageCard: ImageCard[];
}

interface NumberedListProps {
  title: string;
  mainColor: string;
  more: string;
  list: string[];
}

interface ReviewItem {
  title: string;
  time: string;
  name: string;
  review: string;
}

interface ReviewsCardsListProps {
  title: string;
  color: string;
  bgColor: string;
  height: string;
  list: ReviewItem[];
}

interface UpgradeDisclaimerProps {
  text?: string;
  cp: string;
}

interface DogFamousProps {
  bannerOne: BannerOneProps;
  imageTextCheckBoxSection: ImageTextCheckBoxSectionProps;
  planSection: PlanSectionProps;
  PlanSectionComponent: FC<PlanSectionProps>;
  numberedList: NumberedListProps;
  imageGrid: ImageGridProps;
  reviewsSection: ReviewsCardsListProps;
  planSection2: PlanSectionProps;
  userHasBoughtPaperbackBook: boolean;
  paymentSteps: PaymentStepsProps;
  disclaimer: UpgradeDisclaimerProps;
}

const DogFamous: FC<DogFamousProps> = ({
  bannerOne,
  imageTextCheckBoxSection,
  planSection,
  PlanSectionComponent,
  numberedList,
  imageGrid,
  reviewsSection,
  planSection2,
  userHasBoughtPaperbackBook,
  paymentSteps,
  disclaimer,
}) => {
  const PlanSectionComponentStyled = styled(PlanSectionComponent)`
    padding: 4rem 1rem;
    @media ${tablet} {
      padding: 2.25rem 1rem;
    }
  `;
  const selectedPlans = useSelector(
    (state: AppState) => state.user.selected_plans,
  );

  const hasEbookOrPaperback = selectedPlans.some(
    product =>
      product.key.includes('e-book') || product.key.includes('paper-back-book'),
  );

  return (
    <>
      {hasEbookOrPaperback && (
        <BannerOne
          {...bannerOne}
          boughtPaperback={userHasBoughtPaperbackBook}
        />
      )}
      <PaymentSteps
        {...paymentSteps}
        hasOptionalStep={userHasBoughtPaperbackBook}
      />
      <ImageTextCheckBoxSection {...imageTextCheckBoxSection} />
      <PlanSectionComponentStyled {...planSection} />
      <ImageGrid {...imageGrid} />
      <NumberedList {...numberedList} />
      <PlanSectionComponentStyled {...planSection2} />
      <ReviewsCardsList {...reviewsSection} />
      <UpgradeDisclaimer {...disclaimer} />
    </>
  );
};

export default DogFamous;
